import { DatePicker, Form } from 'antd';
import moment from 'moment';
import React from 'react';
import { FundDetailRegionalFocusCountry } from '../../../../../shared/api/models/FundDetails';
import {
  BenchmarkRecord,
  CodeRecord,
  ValueRecord,
  OptionType,
  EsgOptionType,
  MinorityType,
  FundDropdownsType,
} from '../../../../../shared/api/models/ResponsesTypes';
import { FetchFundParams } from '../../../../../shared/api/services/search.service';
import GenUtil from '../../../../../shared/utils/gen-util';
import {
  CustomRendererProps,
  FieldProps,
  FIELD_TYPES,
  PairValueProps,
} from '../helper/advanceFormFields';
import MonthRangePicker from '../MonthRangePicker';
import OtherFiltersPanel from '../OtherFiltersPanel';

const { RangePicker } = DatePicker;

export type AdvancedFieldsType = {
  groupName: string;
  hasNew?: boolean;
  fields: (FieldProps | CustomRendererProps | PairValueProps)[];
};

type Props = {
  data: Partial<FetchFundParams>;
  update: React.Dispatch<Partial<FetchFundParams>>;
  benchmarkOptions: BenchmarkRecord[];
  liquidAltRegionOptions: CodeRecord[];
  maxFrontFeeOptions: ValueRecord[];
  liquidAltTypeOptions: CodeRecord[];
  max12B1FeeOptions: ValueRecord[];
  maxDeferredFeeOptions: ValueRecord[];
  productClassOptions: CodeRecord[];
  stateOptions: string[];
  countryOptions: OptionType[];
  advanceNoticeOptions: string[];
  minInvestmentCurrOptions: string[];
  reportingOptions: string[];
  reportingStyleOptions: string[];
  returnsCurrencyOptions: string[];
  redemptionOptions: string[];
  subscriptionsOptions: string[];
  firmAssetCurrencyOptions: string[];
  fundAssetCurrencyOptions: string[];
  rorDateRangeOptions: string[];
  regionalFocusCountryOptions: FundDetailRegionalFocusCountry[];
  nonSecRegAuthorities: OptionType[];
  nonSecCountryOptions: OptionType[];
  esgStrategyOptions: EsgOptionType[];
  esgSubStrategyOptions: EsgOptionType[];
  minorityTypeOptions: MinorityType[];
  fundDropdowns: FundDropdownsType;
};

export const getAdvancedFields = ({
  data,
  update,
  benchmarkOptions,
  liquidAltRegionOptions,
  maxFrontFeeOptions,
  liquidAltTypeOptions,
  max12B1FeeOptions,
  maxDeferredFeeOptions,
  productClassOptions,
  advanceNoticeOptions,
  minInvestmentCurrOptions,
  reportingOptions,
  reportingStyleOptions,
  returnsCurrencyOptions,
  redemptionOptions,
  subscriptionsOptions,
  firmAssetCurrencyOptions,
  fundAssetCurrencyOptions,
  stateOptions,
  countryOptions,
  rorDateRangeOptions,
  regionalFocusCountryOptions,
  nonSecRegAuthorities,
  nonSecCountryOptions,
  esgStrategyOptions,
  esgSubStrategyOptions,
  minorityTypeOptions,
  fundDropdowns,
}: Props): AdvancedFieldsType[] => {
  let filteredFocusCountry: FundDetailRegionalFocusCountry[] = [];
  if (data.regionalFocus && data.regionalFocus.length > 0) {
    filteredFocusCountry = regionalFocusCountryOptions.filter(
      regionalFocusCountry =>
        regionalFocusCountry.code_code &&
        data.regionalFocus &&
        data.regionalFocus.some(
          regionFocus =>
            regionFocus === +regionalFocusCountry.code_code.slice(0, regionFocus.toString().length),
        ),
    );
  } else {
    filteredFocusCountry = regionalFocusCountryOptions;
  }

  const transformedEsgSubStrategyOptions = Array.from(
    new Set(esgSubStrategyOptions.map(op => op.name)),
  ).map(opName => {
    return {
      value: opName,
      label: opName,
    };
  });

  return [
    {
      groupName: 'General',
      fields: [
        {
          name: 'UCITS Compliant',
          key: 'ucitsCompliant',
          type: FIELD_TYPES.BOOLEAN,
        },
      ],
    },
    {
      groupName: 'Liquid Alt Info',
      fields: [
        {
          key: 'liquidAltProduct',
          name: 'Liquid Alt Product',
          type: FIELD_TYPES.BOOLEAN,
        },
        {
          key: 'liquidAltRegion',
          name: 'Liquid Alt Regions',
          type: FIELD_TYPES.DROPDOWN,
          isMultiple: true,
          optionData: liquidAltRegionOptions.map(item => ({
            label: item.code_description,
            value: item.code_description,
          })),
        },
        {
          key: 'maxFrontFee',
          name: 'Max Front Fees',
          type: FIELD_TYPES.DROPDOWN,
          isMultiple: true,
          optionData: maxFrontFeeOptions.map(item => ({
            label: item.value,
            value: item.value,
          })),
        },
        {
          key: 'liquidAltType',
          name: 'Liquid Alt Type',
          type: FIELD_TYPES.DROPDOWN,
          isMultiple: true,
          optionData: liquidAltTypeOptions.map(item => ({
            label: item.code_description,
            value: item.code_description,
          })),
        },
        {
          key: 'max_12b1Fee',
          name: 'Max 12 B1 Fees',
          type: FIELD_TYPES.DROPDOWN,
          isMultiple: true,
          optionData: max12B1FeeOptions.map(item => ({
            label: item.value,
            value: item.value,
          })),
        },
        {
          key: 'maxDeferredFee',
          name: 'Max Deferred Fees',
          type: FIELD_TYPES.DROPDOWN,
          isMultiple: true,
          optionData: maxDeferredFeeOptions.map(item => ({
            label: item.value,
            value: item.value,
          })),
        },
      ],
    },
    {
      groupName: 'Fund Info',
      fields: [
        {
          key: 'fundName',
          name: 'Fund Name',
          type: FIELD_TYPES.INPUT_TEXT,
        },
        {
          key: 'isHfrxFund',
          name: 'In HFRX?',
          type: FIELD_TYPES.BOOLEAN,
        },
        {
          key: 'fundId',
          name: 'Fund Id',
          type: FIELD_TYPES.INPUT_TEXT,
        },
        // {
        //   key: 'isOffshore',
        //   name: 'Is Offshore Vehicle?',
        //   type: FIELD_TYPES.BOOLEAN,
        // },
        {
          key: 'isHfruFund',
          name: 'Is HFRU Fund?',
          type: FIELD_TYPES.BOOLEAN,
        },
        {
          key: 'isHfrRiskParityFund',
          name: 'Is HFR Risk Parity Fund?',
          type: FIELD_TYPES.BOOLEAN,
        },
        {
          key: 'isHfrBlockchainFund',
          name: 'Is HFR Blockchain Fund?',
          type: FIELD_TYPES.BOOLEAN,
        },
        {
          key: 'productClass',
          name: 'Product Class',
          type: FIELD_TYPES.DROPDOWN,
          isMultiple: true,
          optionData: productClassOptions.map(item => ({
            label: item.code_description,
            value: item.code_code,
          })),
        },
        {
          key: 'isEmrgngmrktsFund',
          name: 'In Emerging Markets?',
          type: FIELD_TYPES.BOOLEAN,
        },
        {
          key: 'strategyDescription',
          name: 'Strategy Description',
          type: FIELD_TYPES.INPUT_TEXT,
        },
        {
          key: 'isIndexFund',
          name: 'In HFRI?',
          type: FIELD_TYPES.BOOLEAN,
        },
        {
          key: 'regionInvFocusCountry',
          name: 'Regional Investment Focus Sub-Region',
          type: FIELD_TYPES.DROPDOWN,
          isMultiple: true,
          optionData: filteredFocusCountry
            .sort((a, b) => (a.code_description > b.code_description ? 1 : -1))
            .map(item => {
              return {
                label: `${item.code_description}`,
                value: item.code_code,
              };
            }),
        },
        {
          key: ['inceptionDateFrom', 'inceptionDateTo'],
          name: 'Inception Date',
          type: FIELD_TYPES.CUSTOM,
          renderer: (
            <Form.Item
              key="inceptionDateFromTo"
              label={'Inception Date'}
              style={{ marginRight: 16 }}
            >
              <RangePicker
                value={
                  !data || (!data.inceptionDateFrom && !data.inceptionDateTo)
                    ? undefined
                    : [moment(data.inceptionDateFrom), moment(data.inceptionDateTo!)]
                }
                onChange={dates => {
                  update({
                    inceptionDateFrom: dates[0]
                      ? GenUtil.getFormattedYearMonthAndDay(dates[0])
                      : undefined,
                  });
                  update({
                    inceptionDateTo: dates[1]
                      ? GenUtil.getFormattedYearMonthAndDay(dates[1])
                      : undefined,
                  });
                }}
              />
            </Form.Item>
          ),
        },
        {
          key: ['addedToDbFrom', 'addedToDbTo'],
          name: 'Date added to DB',
          type: FIELD_TYPES.CUSTOM,
          renderer: (
            <Form.Item key="addedToDbFromTo" label={'Date added to DB'}>
              <RangePicker
                value={
                  !data || (!data.addedToDbFrom && !data.addedToDbTo)
                    ? undefined
                    : [moment(data.addedToDbFrom), moment(data.addedToDbTo!)]
                }
                onChange={dates => {
                  update({
                    addedToDbFrom: dates[0]
                      ? GenUtil.getFormattedYearMonthAndDay(dates[0])
                      : undefined,
                  });
                  update({
                    addedToDbTo: dates[1]
                      ? GenUtil.getFormattedYearMonthAndDay(dates[1])
                      : undefined,
                  });
                }}
              />
            </Form.Item>
          ),
        },
      ],
    },
    {
      groupName: 'Firm Info',
      hasNew: true,
      fields: [
        { key: 'firmId', name: 'Firm ID', type: FIELD_TYPES.INPUT_TEXT },
        { key: 'firmName', name: 'Firm Name', type: FIELD_TYPES.INPUT_TEXT },
        { key: 'iardCrdNumber', name: 'Firm IARD CRD Number', type: FIELD_TYPES.INPUT_TEXT },
        { key: 'secNumber', name: 'Firm SEC Number', type: FIELD_TYPES.INPUT_TEXT },
        {
          key: 'secRegistered',
          name: 'Firm SEC Registered',
          type: FIELD_TYPES.BOOLEAN,
        },
        {
          key: 'nonSecRegCountry',
          name: 'Non-SEC Registration Country',
          type: FIELD_TYPES.DROPDOWN,
          isNew: true,
          isMultiple: true,
          optionData: nonSecCountryOptions.map(option => {
            return {
              label: option.description,
              value: option.code,
            };
          }),
        },
        {
          key: 'nonSecRegAuth',
          name: 'Non-SEC Registration Authority',
          type: FIELD_TYPES.DROPDOWN,
          isNew: true,
          optionData: nonSecRegAuthorities.map(item => {
            return {
              label: item.description,
              value: item.code,
            };
          }),
        },
        {
          key: 'nonSecRegAuthType',
          name: 'Non-SEC Registration Authority Type No',
          type: FIELD_TYPES.INPUT_TEXT,
          isNew: true,
        },
        {
          key: 'contactWeb',
          name: 'Website',
          type: FIELD_TYPES.INPUT_TEXT,
          isNew: true,
        },
        {
          key: 'email',
          name: 'Firm Contact Email',
          type: FIELD_TYPES.INPUT_TEXT,
          isNew: true,
        },
      ],
    },
    {
      groupName: 'Service Provider Info',
      fields: [
        {
          key: 'administrator',
          name: 'Administrator',
          type: FIELD_TYPES.INPUT_TEXT,
        },
        {
          key: 'annualPerformAudit',
          name: 'Annual Perform Audit',
          type: FIELD_TYPES.BOOLEAN,
        },
        // {
        //   key: ['auditDateFrom', 'auditDateTo'],
        //   name: 'Audit Date',
        //   type: FIELD_TYPES.CUSTOM,
        //   renderer: (
        //     <Form.Item key="auditDate" label={'Audit Date'} style={{ width: '31%' }}>
        //       <RangePicker
        //         value={
        //           !data || (!data.auditDateFrom && !data.auditDateTo)
        //             ? undefined
        //             : [moment(data.auditDateFrom), moment(data.auditDateTo!)]
        //         }
        //         onChange={dates => {
        //           update({
        //             auditDateFrom: dates[0]
        //               ? GenUtil.getFormattedYearMonthAndDay(dates[0])
        //               : undefined,
        //           });
        //           update({
        //             auditDateTo: dates[1]
        //               ? GenUtil.getFormattedYearMonthAndDay(dates[1])
        //               : undefined,
        //           });
        //         }}
        //       />
        //       <div />
        //     </Form.Item>
        //   ),
        // },
        {
          key: 'auditor',
          name: 'Auditor',
          type: FIELD_TYPES.INPUT_TEXT,
        },
        {
          key: 'bankingAgentOnshore',
          name: 'Banking Agent Onshore',
          type: FIELD_TYPES.INPUT_TEXT,
        },
        {
          key: 'custodian',
          name: 'Custodian',
          type: FIELD_TYPES.INPUT_TEXT,
        },
        {
          key: 'legalAdviserOnshore',
          name: 'Legal Adviser Onshore',
          type: FIELD_TYPES.INPUT_TEXT,
        },
        {
          key: 'primeBroker',
          name: 'Prime Broker',
          type: FIELD_TYPES.INPUT_TEXT,
        },
      ],
    },
    {
      groupName: 'Contact Info',
      fields: [
        { key: 'principals', name: 'Principals', type: FIELD_TYPES.INPUT_TEXT },
        { key: 'firstContact', name: '1st Contact', type: FIELD_TYPES.INPUT_TEXT },
        { key: 'secondContact', name: '2nd Contact', type: FIELD_TYPES.INPUT_TEXT },
        { key: 'address', name: 'Address', type: FIELD_TYPES.INPUT_TEXT },
        { key: 'city', name: 'City', type: FIELD_TYPES.INPUT_TEXT },
        {
          key: 'state',
          name: 'State',
          type: FIELD_TYPES.DROPDOWN,
          isMultiple: true,
          optionData:
            stateOptions.length > 0
              ? stateOptions.map(state => ({ label: state, value: state }))
              : [],
        },
        {
          key: 'country',
          name: 'Country',
          type: FIELD_TYPES.DROPDOWN,
          isMultiple: true,
          optionData: countryOptions.map(option => {
            return {
              label: option.description,
              value: option.description,
            };
          }),
        },
        { key: 'postalCode', name: 'Postal Code', type: FIELD_TYPES.INPUT_TEXT },
        { key: 'phone', name: 'Phone', type: FIELD_TYPES.INPUT_TEXT },
        // { key: 'facsimile', name: 'Facsimile', type: FIELD_TYPES.INPUT_TEXT },
      ],
    },
    {
      groupName: 'Investment Info',
      fields: [
        {
          key: 'advanceNotice',
          name: 'Advance Notice',
          type: FIELD_TYPES.DROPDOWN,
          isMultiple: true,
          optionData: advanceNoticeOptions.map(item => ({ label: item, value: item })),
        },
        {
          key: 'domicile',
          name: 'Domicile',
          type: FIELD_TYPES.INPUT_TEXT,
        },
        {
          key: 'structure',
          name: 'Structure',
          type: FIELD_TYPES.INPUT_TEXT,
        },
        {
          key: 'highWatermark',
          name: 'High Watermark',
          type: FIELD_TYPES.BOOLEAN,
        },
        {
          key: 'hurdleRate',
          name: 'Hurdle Rate',
          type: FIELD_TYPES.INPUT_TEXT,
        },
        {
          type: FIELD_TYPES.PAIR_VALUE,
          name: 'Individual Account Min.',
          atleastKey: 'individualAccountMinGte',
          atmostKey: 'individualAccountMinLte',
        },
        {
          key: 'leverage',
          name: 'Leverage',
          type: FIELD_TYPES.INPUT_TEXT,
        },
        {
          key: 'isLockup',
          name: 'Is Lockup?',
          type: FIELD_TYPES.BOOLEAN,
        },
        {
          key: 'lockup',
          name: 'Lockup',
          type: FIELD_TYPES.INPUT_TEXT,
        },
        {
          key: 'minInvestmentCurr',
          name: 'Min. Investment Curr',
          type: FIELD_TYPES.DROPDOWN,
          isMultiple: true,
          optionData: minInvestmentCurrOptions.map(item => ({ label: item, value: item })),
        },
        {
          type: FIELD_TYPES.PAIR_VALUE,
          name: 'Minimum Investment',
          atleastKey: 'minimumInvestmentGte',
          atmostKey: 'minimumInvestmentLte',
        },
        {
          type: FIELD_TYPES.PAIR_VALUE,
          name: 'Additional Investment',
          atleastKey: 'additionalInvestmentGte',
          atmostKey: 'additionalInvestmentLte',
        },
        {
          key: 'openForInvestment',
          name: 'Open For Investment',
          type: FIELD_TYPES.BOOLEAN,
        },
        {
          key: 'openToInvestorType',
          name: 'Open To Investor Type',
          type: FIELD_TYPES.INPUT_TEXT,
        },
        // {
        //   key: 'reporting',
        //   name: 'Reporting',
        //   type: FIELD_TYPES.DROPDOWN,
        //   isMultiple: true,
        //   optionData: reportingOptions.map(item => ({ label: item, value: item })),
        // },
        {
          key: 'reportingStyle',
          name: 'Reporting Style',
          type: FIELD_TYPES.DROPDOWN,
          isMultiple: true,
          optionData: reportingStyleOptions.map(item => ({ label: item, value: item })),
        },
        {
          key: 'returnsCurrency',
          name: 'Returns Currency',
          type: FIELD_TYPES.DROPDOWN,
          isMultiple: true,
          optionData: returnsCurrencyOptions.map(item => ({ label: item, value: item })),
        },
        {
          key: 'fundRegistrationCode',
          name: 'Fund Registration Code',
          type: FIELD_TYPES.INPUT_TEXT,
        },
        {
          key: 'redemptions',
          name: 'Redemptions',
          type: FIELD_TYPES.DROPDOWN,
          isMultiple: true,
          optionData: redemptionOptions.map(item => ({ label: item, value: item })),
        },
        {
          key: 'subscriptions',
          name: 'Subscriptions',
          type: FIELD_TYPES.DROPDOWN,
          isMultiple: true,
          optionData: subscriptionsOptions.map(item => ({ label: item, value: item })),
        },
        {
          key: 'mgmtFee',
          name: 'Management Fees %',
          type: FIELD_TYPES.INPUT_TEXT,
        },
        {
          key: 'incentiveFee',
          name: 'Incentive Fees %',
          type: FIELD_TYPES.INPUT_TEXT,
        },
      ],
    },
    {
      groupName: 'Asset Info',
      fields: [
        {
          key: 'firmAssetsCurrency',
          name: 'Firm Assets Currency',
          isMultiple: true,
          type: FIELD_TYPES.DROPDOWN,
          optionData: firmAssetCurrencyOptions.map(item => ({ label: item, value: item })),
        },
        {
          key: ['firmAssetsRepDateFrom', 'firmAssetsRepDateTo'],
          name: 'Firm Assets Rep Date',
          type: FIELD_TYPES.CUSTOM,
          renderer: (
            <Form.Item label={'Firm Assets Rep Date'} key="firmAssetsRepDate">
              <RangePicker
                value={
                  !data || (!data.firmAssetsRepDateFrom && !data.firmAssetsRepDateTo)
                    ? undefined
                    : [moment(data.firmAssetsRepDateFrom), moment(data.firmAssetsRepDateTo!)]
                }
                onChange={dates => {
                  update({
                    firmAssetsRepDateFrom: dates[0]
                      ? GenUtil.getFormattedYearMonthAndDay(dates[0])
                      : undefined,
                  });
                  update({
                    firmAssetsRepDateTo: dates[1]
                      ? GenUtil.getFormattedYearMonthAndDay(dates[1])
                      : undefined,
                  });
                }}
              />
            </Form.Item>
          ),
        },
        {
          key: 'fundAssetsCurrency',
          name: 'Fund Assets Currency',
          isMultiple: true,
          type: FIELD_TYPES.DROPDOWN,
          optionData: fundAssetCurrencyOptions.map(item => ({ label: item, value: item })),
        },
        {
          key: ['fundAssetsRepDateFrom', 'fundAssetsRepDateTo'],
          name: 'Fund Assets Rep Date',
          type: FIELD_TYPES.CUSTOM,
          renderer: (
            <Form.Item label={'Fund Assets Rep Date'} key="fundAssetsRepDate">
              <RangePicker
                value={
                  !data || (!data.fundAssetsRepDateFrom && !data.fundAssetsRepDateTo)
                    ? undefined
                    : [moment(data.fundAssetsRepDateFrom), moment(data.fundAssetsRepDateTo!)]
                }
                onChange={dates => {
                  update({
                    fundAssetsRepDateFrom: dates[0]
                      ? GenUtil.getFormattedYearMonthAndDay(dates[0])
                      : undefined,
                  });
                  update({
                    fundAssetsRepDateTo: dates[1]
                      ? GenUtil.getFormattedYearMonthAndDay(dates[1])
                      : undefined,
                  });
                }}
              />
            </Form.Item>
          ),
        },
        {
          name: 'Firm USD Value (M)',
          atleastKey: 'firmUsdValueGte',
          atmostKey: 'firmUsdValueLte',
          type: FIELD_TYPES.PAIR_VALUE,
        },
        {
          name: 'Fund USD Value (M)',
          atleastKey: 'fundUsdValueGte',
          atmostKey: 'fundUsdValueLte',
          type: FIELD_TYPES.PAIR_VALUE,
        },
      ],
    },
    {
      groupName: 'Statistics Filters',
      hasNew: true,
      fields: [
        {
          key: ['latestRORDateFrom', 'latestRORDateTo'],
          name: 'Latest ROR Date',
          type: FIELD_TYPES.CUSTOM,
          renderer: (
            <MonthRangePicker
              key="latest-ror-date-range"
              data={data}
              update={update}
              options={rorDateRangeOptions}
            />
          ),
        },
        {
          name: 'Latest ROR Value',
          atleastKey: 'latestRORValueGte',
          atmostKey: 'latestRORValueLte',
          type: FIELD_TYPES.PAIR_VALUE,
        },
        {
          name: 'Track Record',
          atleastKey: 'trackRecordGte',
          atmostKey: 'trackRecordLte',
          type: FIELD_TYPES.PAIR_VALUE,
        },
        {
          name: 'Total Track Record',
          atleastKey: 'totalTrackRecordGte',
          atmostKey: 'totalTrackRecordLte',
          type: FIELD_TYPES.PAIR_VALUE,
        },
        {
          name: 'Ann ROR %',
          atleastKey: 'annRorGte',
          atmostKey: 'annRorLte',
          type: FIELD_TYPES.PAIR_VALUE,
        },
        {
          name: 'Ann Stdev %',
          atleastKey: 'annStdevGte',
          atmostKey: 'annStdevLte',
          type: FIELD_TYPES.PAIR_VALUE,
        },
        {
          name: 'Geo Avg Month ROR %',
          atleastKey: 'geoAvgMonthRorGte',
          atmostKey: 'geoAvgMonthRorLte',
          type: FIELD_TYPES.PAIR_VALUE,
        },
        {
          name: 'High Month ROR %',
          atleastKey: 'highMonthRorGte',
          atmostKey: 'highMonthRorLte',
          type: FIELD_TYPES.PAIR_VALUE,
        },
        {
          name: 'Low Month ROR %',
          atleastKey: 'lowMonthRorGte',
          atmostKey: 'lowMonthRorLte',
          type: FIELD_TYPES.PAIR_VALUE,
        },
        {
          name: 'Stdev %',
          atleastKey: 'stdevGte',
          atmostKey: 'stdevLte',
          type: FIELD_TYPES.PAIR_VALUE,
        },
        {
          name: 'Sharpe Ratio',
          atleastKey: 'sharpeRatioGte',
          atmostKey: 'sharpeRatioLte',
          type: FIELD_TYPES.PAIR_VALUE,
        },
        {
          name: 'One Month ROR %',
          atleastKey: 'oneMonthRorGte',
          atmostKey: 'oneMonthRorLte',
          type: FIELD_TYPES.PAIR_VALUE,
        },
        {
          name: 'Three Month ROR %',
          atleastKey: 'threeMonthRorGte',
          atmostKey: 'threeMonthRorLte',
          type: FIELD_TYPES.PAIR_VALUE,
        },
        {
          name: 'Six Month ROR %',
          atleastKey: 'sixMonthRorGte',
          atmostKey: 'sixMonthRorLte',
          type: FIELD_TYPES.PAIR_VALUE,
        },
        {
          name: '1 Year Ann ROR %',
          atleastKey: 'oneYearAnnRorGte',
          atmostKey: 'oneYearAnnRorLte',
          type: FIELD_TYPES.PAIR_VALUE,
        },
        {
          name: '1 Year Ann Stdev %',
          atleastKey: 'oneYearAnnStdevGte',
          atmostKey: 'oneYearAnnStdevLte',
          type: FIELD_TYPES.PAIR_VALUE,
        },
        {
          name: '1 Year Ann Sharpe Ratio',
          atleastKey: 'oneYearAnnSharpeRatioGte',
          atmostKey: 'oneYearAnnSharpeRatioLte',
          type: FIELD_TYPES.PAIR_VALUE,
        },
        {
          name: '2 Year Ann ROR %',
          atleastKey: 'twoYearAnnRorGte',
          atmostKey: 'twoYearAnnRorLte',
          type: FIELD_TYPES.PAIR_VALUE,
        },
        {
          name: '2 Year Ann Stdev %',
          atleastKey: 'twoYearAnnStdevGte',
          atmostKey: 'twoYearAnnStdevLte',
          type: FIELD_TYPES.PAIR_VALUE,
        },
        {
          name: '2 Year Ann Sharpe Ratio',
          atleastKey: 'twoYearAnnSharpeRatioGte',
          atmostKey: 'twoYearAnnSharpeRatioLte',
          type: FIELD_TYPES.PAIR_VALUE,
        },
        {
          name: '3 Year Ann ROR %',
          atleastKey: 'threeYearAnnRorGte',
          atmostKey: 'threeYearAnnRorLte',
          type: FIELD_TYPES.PAIR_VALUE,
        },
        {
          name: '3 Year Ann Stdev %',
          atleastKey: 'threeYearAnnStdevGte',
          atmostKey: 'threeYearAnnStdevLte',
          type: FIELD_TYPES.PAIR_VALUE,
        },
        {
          name: '3 Year Ann Sharpe Ratio',
          atleastKey: 'threeYearAnnSharpeRatioGte',
          atmostKey: 'threeYearAnnSharpeRatioLte',
          type: FIELD_TYPES.PAIR_VALUE,
        },
        {
          name: '5 Year Ann ROR %',
          atleastKey: 'fiveYearAnnRorGte',
          atmostKey: 'fiveYearAnnRorLte',
          type: FIELD_TYPES.PAIR_VALUE,
        },
        {
          name: '5 Year Ann Stdev %',
          atleastKey: 'fiveYearAnnStdevGte',
          atmostKey: 'fiveYearAnnStdevLte',
          type: FIELD_TYPES.PAIR_VALUE,
        },
        {
          name: '5 Year Ann Sharpe Ratio',
          atleastKey: 'fiveYearAnnSharpeRatioGte',
          atmostKey: 'fiveYearAnnSharpeRatioLte',
          type: FIELD_TYPES.PAIR_VALUE,
        },
        {
          name: '7 Year Ann ROR %',
          atleastKey: 'sevenYearAnnRorGte',
          atmostKey: 'sevenYearAnnRorLte',
          type: FIELD_TYPES.PAIR_VALUE,
        },
        {
          name: '7 Year Ann Stdev %',
          atleastKey: 'sevenYearAnnStdevGte',
          atmostKey: 'sevenYearAnnStdevLte',
          type: FIELD_TYPES.PAIR_VALUE,
        },
        {
          name: '7 Year Ann Sharpe Ratio',
          atleastKey: 'sevenYearAnnSharpeRatioGte',
          atmostKey: 'sevenYearAnnSharpeRatioLte',
          type: FIELD_TYPES.PAIR_VALUE,
        },
        {
          name: '10 Year Ann ROR %',
          atleastKey: 'tenYearAnnRorGte',
          atmostKey: 'tenYearAnnRorLte',
          type: FIELD_TYPES.PAIR_VALUE,
        },
        {
          name: '10 Year Ann Stdev %',
          atleastKey: 'tenYearAnnStdevGte',
          atmostKey: 'tenYearAnnStdevLte',
          type: FIELD_TYPES.PAIR_VALUE,
        },
        {
          name: '10 Year Ann Sharpe Ratio',
          atleastKey: 'tenYearAnnSharpeRatioGte',
          atmostKey: 'tenYearAnnSharpeRatioLte',
          type: FIELD_TYPES.PAIR_VALUE,
        },
        {
          name: 'Max Drawdown',
          atleastKey: 'max_drawdown_gte',
          atmostKey: 'max_drawdown_lte',
          isNew: true,
          type: FIELD_TYPES.PAIR_VALUE,
        },
        {
          name: 'YTD',
          atleastKey: 'ytd_gte',
          atmostKey: 'ytd_lte',
          isNew: true,
          type: FIELD_TYPES.PAIR_VALUE,
        },
      ],
    },
    {
      groupName: 'MPT Statistics',
      fields: [
        {
          name: 'Alpha',
          atleastKey: 'alphaGte',
          atmostKey: 'alphaLte',
          type: FIELD_TYPES.PAIR_VALUE,
        },
        { name: 'Beta', atleastKey: 'betaGte', atmostKey: 'betaLte', type: FIELD_TYPES.PAIR_VALUE },
        {
          name: 'R-Squared',
          atleastKey: 'rSquareGte',
          atmostKey: 'rSquareLte',
          type: FIELD_TYPES.PAIR_VALUE,
        },
        {
          name: 'Correlation',
          atleastKey: 'correlationGte',
          atmostKey: 'correlationLte',
          type: FIELD_TYPES.PAIR_VALUE,
        },
        {
          name: 'Up Alpha',
          atleastKey: 'upAlphaGte',
          atmostKey: 'upAlphaLte',
          type: FIELD_TYPES.PAIR_VALUE,
        },
        {
          name: 'Up Beta',
          atleastKey: 'upBetaGte',
          atmostKey: 'upBetaLte',
          type: FIELD_TYPES.PAIR_VALUE,
        },
        {
          name: 'Up R-Squared',
          atleastKey: 'upRSquareGte',
          atmostKey: 'upRSquareLte',
          type: FIELD_TYPES.PAIR_VALUE,
        },
        {
          name: 'Down Alpha',
          atleastKey: 'downAlphaGte',
          atmostKey: 'downAlphaLte',
          type: FIELD_TYPES.PAIR_VALUE,
        },
        {
          name: 'Down Beta',
          atleastKey: 'downBetaGte',
          atmostKey: 'downBetaLte',
          type: FIELD_TYPES.PAIR_VALUE,
        },
        {
          name: 'Down R-Squared',
          atleastKey: 'downRSquareGte',
          atmostKey: 'downRSquareLte',
          type: FIELD_TYPES.PAIR_VALUE,
        },
      ],
    },
    {
      groupName: 'Stats Calculation Settings',
      hasNew: true,
      fields: [
        {
          key: ['statsStartDate', 'statsEndDate'],
          name: 'Stats Start/End Date',
          type: FIELD_TYPES.CUSTOM,
          renderer: data => (
            <OtherFiltersPanel
              key="other-filter"
              data={data}
              update={update}
              options={rorDateRangeOptions}
            />
          ),
        },
        {
          key: 'stats_benchmark',
          name: 'Benchmark',
          isNew: true,
          type: FIELD_TYPES.DROPDOWN,
          isMultiple: false,
          optionData: benchmarkOptions.map(item => ({
            label: item.name,
            value: item.id,
          })),
        },
      ],
    },
    {
      groupName: 'Fund ESG',
      hasNew: true,
      fields: [
        {
          key: 'is_fund_esg',
          name: 'Is Fund ESG?',
          isNew: true,
          type: FIELD_TYPES.DROPDOWN,
          isMultiple: true,
          optionData: fundDropdowns.NRYN
            ? fundDropdowns.NRYN.map(op => {
                return {
                  value: op,
                  label: op,
                };
              })
            : [],
        },
        {
          key: 'esg_strategy',
          name: 'ESG Strategy',
          isNew: true,
          type: FIELD_TYPES.DROPDOWN,
          isMultiple: true,
          optionData: esgStrategyOptions.map(op => {
            return {
              value: op.name,
              label: op.name,
            };
          }),
        },
        {
          key: 'esg_sub_strategy',
          name: 'ESG Sub-Strategy',
          isNew: true,
          type: FIELD_TYPES.DROPDOWN,
          isMultiple: true,
          optionData: transformedEsgSubStrategyOptions,
        },
        //
        {
          key: 'app_positivescreen',
          name: 'Positive/best-in-class Screening',
          isNew: true,
          type: FIELD_TYPES.DROPDOWN,
          isMultiple: true,
          optionData: BooleanOptions,
        },
        {
          key: 'app_negativescreen',
          name: 'Negative/Exclusionary Screening',
          isNew: true,
          type: FIELD_TYPES.DROPDOWN,
          isMultiple: true,
          optionData: BooleanOptions,
        },
        {
          key: 'app_esgintegration',
          name: 'ESG Integration',
          isNew: true,
          type: FIELD_TYPES.DROPDOWN,
          isMultiple: true,
          optionData: BooleanOptions,
        },
        {
          key: 'app_impactinvest',
          name: 'Impact Investing',
          isNew: true,
          type: FIELD_TYPES.DROPDOWN,
          isMultiple: true,
          optionData: BooleanOptions,
        },
        {
          key: 'app_sustaininvest',
          name: 'Sustainability-themed Investing',
          isNew: true,
          type: FIELD_TYPES.DROPDOWN,
          isMultiple: true,
          optionData: BooleanOptions,
        },
        //
        {
          key: 'env_greenbuilding',
          name: 'Green Building / Smart Growth',
          isNew: true,
          type: FIELD_TYPES.DROPDOWN,
          isMultiple: true,
          optionData: BooleanOptions,
        },
        {
          key: 'env_pollutiontaxes',
          name: 'Pollution / Toxic',
          isNew: true,
          type: FIELD_TYPES.DROPDOWN,
          isMultiple: true,
          optionData: BooleanOptions,
        },
        {
          key: 'env_climatechange',
          name: 'Climate Change / Carbon',
          isNew: true,
          type: FIELD_TYPES.DROPDOWN,
          isMultiple: true,
          optionData: BooleanOptions,
        },
        {
          key: 'env_sustainable',
          name: 'Sustainable Nature Resources / Agriculture',
          isNew: true,
          type: FIELD_TYPES.DROPDOWN,
          isMultiple: true,
          optionData: BooleanOptions,
        },
        {
          key: 'env_cleantech',
          name: 'Clean Technology',
          isNew: true,
          type: FIELD_TYPES.DROPDOWN,
          isMultiple: true,
          optionData: BooleanOptions,
        },
        {
          key: 'env_waterconservation',
          name: 'Water Use Conservation',
          isNew: true,
          type: FIELD_TYPES.DROPDOWN,
          isMultiple: true,
          optionData: BooleanOptions,
        },
        //
        {
          key: 'soc_workplacesafety',
          name: 'Workplace Safety',
          isNew: true,
          type: FIELD_TYPES.DROPDOWN,
          isMultiple: true,
          optionData: BooleanOptions,
        },
        {
          key: 'soc_communitydev',
          name: 'Community Development',
          isNew: true,
          type: FIELD_TYPES.DROPDOWN,
          isMultiple: true,
          optionData: BooleanOptions,
        },
        {
          key: 'soc_laborrelations',
          name: 'Labor Relations',
          isNew: true,
          type: FIELD_TYPES.DROPDOWN,
          isMultiple: true,
          optionData: BooleanOptions,
        },
        {
          key: 'soc_workplacebenefits',
          name: 'Workplace Benefits',
          isNew: true,
          type: FIELD_TYPES.DROPDOWN,
          isMultiple: true,
          optionData: BooleanOptions,
        },
        {
          key: 'soc_avoidtobacco',
          name: 'Avoidance of Tobacco/Harmful Products',
          isNew: true,
          type: FIELD_TYPES.DROPDOWN,
          isMultiple: true,
          optionData: BooleanOptions,
        },
        {
          key: 'soc_diversityantibias',
          name: 'Diversity Anti Bias',
          isNew: true,
          type: FIELD_TYPES.DROPDOWN,
          isMultiple: true,
          optionData: BooleanOptions,
        },
        {
          key: 'soc_humanrights',
          name: 'Human Rights',
          isNew: true,
          type: FIELD_TYPES.DROPDOWN,
          isMultiple: true,
          optionData: BooleanOptions,
        },
        //
        {
          key: 'gov_corppolcontrib',
          name: 'Corporate Political Contributions',
          isNew: true,
          type: FIELD_TYPES.DROPDOWN,
          isMultiple: true,
          optionData: BooleanOptions,
        },
        {
          key: 'gov_anticorruption',
          name: 'Anti Corruption Policies',
          isNew: true,
          type: FIELD_TYPES.DROPDOWN,
          isMultiple: true,
          optionData: BooleanOptions,
        },
        {
          key: 'gov_executivecomp',
          name: 'Executive Compensation',
          isNew: true,
          type: FIELD_TYPES.DROPDOWN,
          isMultiple: true,
          optionData: BooleanOptions,
        },
        {
          key: 'gov_boardindependence',
          name: 'Board Independence',
          isNew: true,
          type: FIELD_TYPES.DROPDOWN,
          isMultiple: true,
          optionData: BooleanOptions,
        },
        {
          key: 'gov_boarddiversity',
          name: 'Board Diversity',
          isNew: true,
          type: FIELD_TYPES.DROPDOWN,
          isMultiple: true,
          optionData: BooleanOptions,
        },
        //
        {
          key: 'team_engagepcm',
          name: 'Engage PCM?',
          isNew: true,
          type: FIELD_TYPES.DROPDOWN,
          isMultiple: true,
          optionData: BooleanOptions,
        },
        // HIDE FOR NOW
        // {
        //   key: 'data_msci',
        //   name: 'MSCI',
        //   isNew: true,
        //   type: FIELD_TYPES.DROPDOWN,
        //   isMultiple: true,
        //   optionData: BooleanOptions,
        // },
        // {
        //   key: 'data_sustainalytics',
        //   name: 'Sustainalytics',
        //   isNew: true,
        //   type: FIELD_TYPES.DROPDOWN,
        //   isMultiple: true,
        //   optionData: BooleanOptions,
        // },
        // {
        //   key: 'data_bloomberg',
        //   name: 'Bloomberg',
        //   isNew: true,
        //   type: FIELD_TYPES.DROPDOWN,
        //   isMultiple: true,
        //   optionData: BooleanOptions,
        // },
        // {
        //   key: 'data_factset',
        //   name: 'Factset',
        //   isNew: true,
        //   type: FIELD_TYPES.DROPDOWN,
        //   isMultiple: true,
        //   optionData: BooleanOptions,
        // },
        // {
        //   key: 'data_internal',
        //   name: 'Proprietary or Internal',
        //   isNew: true,
        //   type: FIELD_TYPES.DROPDOWN,
        //   isMultiple: true,
        //   optionData: BooleanOptions,
        // },
      ],
    },
    {
      groupName: 'Firm ESG',
      hasNew: true,
      fields: [
        {
          key: 'is_firm_esg',
          name: 'Is Firm ESG?',
          isNew: true,
          type: FIELD_TYPES.DROPDOWN,
          isMultiple: true,
          optionData: fundDropdowns.NRYN
            ? fundDropdowns.NRYN.map(op => {
                return {
                  value: op,
                  label: op,
                };
              })
            : [],
        },
        {
          key: 'applied_firmculture',
          name: 'Firm ESG Firm Culture',
          isNew: true,
          type: FIELD_TYPES.DROPDOWN,
          isMultiple: true,
          optionData: fundDropdowns.NRYN
            ? fundDropdowns.NRYN.map(op => {
                return {
                  value: op,
                  label: op,
                };
              })
            : [],
        },
        {
          key: 'applied_investmentprocess',
          name: 'Firm ESG Investment Process',
          isNew: true,
          type: FIELD_TYPES.DROPDOWN,
          isMultiple: true,
          optionData: fundDropdowns.NRYN
            ? fundDropdowns.NRYN.map(op => {
                return {
                  value: op,
                  label: op,
                };
              })
            : [],
        },
        {
          key: 'applied_portfolioconstruction',
          name: 'Firm ESG Portfolio Construction',
          isNew: true,
          type: FIELD_TYPES.DROPDOWN,
          isMultiple: true,
          optionData: fundDropdowns.NRYN
            ? fundDropdowns.NRYN.map(op => {
                return {
                  value: op,
                  label: op,
                };
              })
            : [],
        },
        {
          key: 'applied_esgproducts',
          name: 'Firm ESG Products',
          isNew: true,
          type: FIELD_TYPES.DROPDOWN,
          isMultiple: true,
          optionData: fundDropdowns.NRYN
            ? fundDropdowns.NRYN.map(op => {
                return {
                  value: op,
                  label: op,
                };
              })
            : [],
        },
        {
          key: 'applied_esgintegration',
          name: 'Firm ESG Integration',
          isNew: true,
          type: FIELD_TYPES.DROPDOWN,
          isMultiple: true,
          optionData: fundDropdowns.NRYN
            ? fundDropdowns.NRYN.map(op => {
                return {
                  value: op,
                  label: op,
                };
              })
            : [],
        },
        {
          key: 'receive_guidance',
          name: 'Firm ESG Receive Guidance',
          isNew: true,
          type: FIELD_TYPES.DROPDOWN,
          isMultiple: true,
          optionData: fundDropdowns.ESGRG
            ? fundDropdowns.ESGRG.map(op => {
                return {
                  value: op,
                  label: op,
                };
              })
            : [],
        },
        {
          key: 'thirdparty_guidance',
          name: 'Firm ESG 3rd Party Guidance',
          isNew: true,
          type: FIELD_TYPES.DROPDOWN,
          isMultiple: true,
          optionData: fundDropdowns.ESGTG
            ? fundDropdowns.ESGTG.map(op => {
                return {
                  value: op,
                  label: op,
                };
              })
            : [],
        },
        {
          key: 'future',
          name: 'Firm ESG Future',
          isNew: true,
          type: FIELD_TYPES.DROPDOWN,
          isMultiple: true,
          optionData: fundDropdowns.NRYN
            ? fundDropdowns.NRYN.map(op => {
                return {
                  value: op,
                  label: op,
                };
              })
            : [],
        },
        {
          key: 'applied_esgactivist',
          name: 'Firm ESG Activist / Engaged Ownership',
          isNew: true,
          type: FIELD_TYPES.DROPDOWN,
          isMultiple: true,
          optionData: fundDropdowns.NRYN
            ? fundDropdowns.NRYN.map(op => {
                return {
                  value: op,
                  label: op,
                };
              })
            : [],
        },
        {
          key: 'applied_diversityinclusion',
          name: 'Firm ESG Diversity And Inclusion',
          isNew: true,
          type: FIELD_TYPES.DROPDOWN,
          isMultiple: true,
          optionData: fundDropdowns.NRYN
            ? fundDropdowns.NRYN.map(op => {
                return {
                  value: op,
                  label: op,
                };
              })
            : [],
        },
      ],
    },
    {
      groupName: 'Fund Diversity & Inclusion',
      hasNew: true,
      fields: [
        {
          key: 'is_minority_or_woman_managed_fund',
          name: 'Is Minority or Woman Managed Fund?',
          isNew: true,
          type: FIELD_TYPES.DROPDOWN,
          isMultiple: true,
          optionData: fundDropdowns.NRYN
            ? fundDropdowns.NRYN.map(op => {
                return {
                  value: op,
                  label: op,
                };
              })
            : [],
        },
        {
          key: 'fund_diversity_and_inclusion_types',
          name: 'Fund Diversity & Inclusion Types',
          isNew: true,
          type: FIELD_TYPES.DROPDOWN,
          isMultiple: true,
          optionData: minorityTypeOptions.map(op => {
            return {
              label: op,
              value: op,
            };
          }),
        },
      ],
    },
    {
      groupName: 'Firm Diversity & Inclusion',
      hasNew: true,
      fields: [
        {
          key: 'is_minority_or_woman_owned_firm',
          name: 'Is Minority or Woman Owned Firm?',
          isNew: true,
          type: FIELD_TYPES.DROPDOWN,
          isMultiple: true,
          optionData: fundDropdowns.NRYN
            ? fundDropdowns.NRYN.map(op => {
                return {
                  value: op,
                  label: op,
                };
              })
            : [],
        },
        {
          key: 'firm_diversity_and_inclusion_types',
          name: 'Firm Diversity & Inclusion Types',
          isNew: true,
          type: FIELD_TYPES.DROPDOWN,
          isMultiple: true,
          optionData: minorityTypeOptions.map(op => {
            return {
              label: op,
              value: op,
            };
          }),
        },
      ],
    },
    {
      groupName: 'Firm Co-Investments',
      hasNew: true,
      fields: [
        {
          key: 'offer_coinvestments',
          name: 'Offer Co-Investments',
          isNew: true,
          type: FIELD_TYPES.DROPDOWN,
          isMultiple: true,
          optionData: fundDropdowns.NRYN
            ? fundDropdowns.NRYN.map(op => {
                return {
                  value: op,
                  label: op,
                };
              })
            : [],
        },
        {
          key: 'typical_size',
          name: 'Typical Deal Size',
          isNew: true,
          type: FIELD_TYPES.DROPDOWN,
          isMultiple: true,
          optionData: fundDropdowns.CISZ
            ? fundDropdowns.CISZ.map(op => {
                return {
                  label: op,
                  value: op,
                };
              })
            : [],
        },
        {
          key: 'typical_liquidity',
          name: 'Typical Liquidity',
          isNew: true,
          type: FIELD_TYPES.DROPDOWN,
          isMultiple: true,
          optionData: fundDropdowns.CILQ
            ? fundDropdowns.CILQ.map(op => {
                return {
                  label: op,
                  value: op,
                };
              })
            : [],
        },
        {
          key: 'number_of_deals_per_year',
          name: 'Number of Deals Per Year',
          isNew: true,
          type: FIELD_TYPES.DROPDOWN,
          isMultiple: true,
          optionData: fundDropdowns.CINUM
            ? fundDropdowns.CINUM.map(op => {
                return {
                  label: op,
                  value: op,
                };
              })
            : [],
        },
      ],
    },
    {
      groupName: 'Fund Family',
      hasNew: true,
      fields: [
        {
          key: 'fund_family_id',
          name: 'Fund Family ID',
          isNew: true,
          type: FIELD_TYPES.INPUT_TEXT,
        },
        // {
        //   key: 'fund_family_code',
        //   name: 'Fund Family Code',
        //   isNew: true,
        //   type: FIELD_TYPES.INPUT_TEXT,
        // },
      ],
    },
  ];
};

const BooleanOptions = [
  { label: 'Yes', value: 'Yes' },
  { label: 'No', value: 'No' },
];
