import React, { useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import styled from 'styled-components';
import { Tabs } from 'antd';
import { connect, MapStateToProps } from 'react-redux';
import PerfectScrollbar from 'react-perfect-scrollbar';
import MainLayout from '../../shared/components/MainLayout';
import { Colors } from '../../shared/colors';
import { useServiceState } from '../../shared/hooks/useServiceState';
import APIService from '../../shared/api';
import { InitialRootReducer } from '../../redux/reducers/root.reducer';
import { UserProfileDetails } from '../../shared/api/models/ResponsesTypes';
import TokenUtil from '../../shared/utils/token-util';

const { TabPane } = Tabs;

const StyledTabs = styled(Tabs)`
  margin: 16px;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  overflow: hidden;
  .ant-tabs-content {
    margin-top: -16px;
    flex-grow: 1;
    background: white;
    border-left: solid 1px ${Colors.border};
    border-right: solid 1px ${Colors.border};
    border-bottom: solid 1px ${Colors.border};
    height: 100%;
    overflow: hidden;

    .ant-tabs-tabpane-active {
      height: 100%;
    }
  }
`;
type Props = {} & RouteComponentProps;
type StateProps = {
  user: UserProfileDetails;
};

const Dashboard: React.FC<Props & StateProps> = ({ user }) => {
  const { data: dashboardListData, invoke: invokeDashboardList } = useServiceState(
    APIService.dashboardService.fetchIndexScopeEmbedList,
  );
  const [activePanel, setActivePanel] = React.useState<string>();

  const { data: dashboardData, invoke: invokeDashboard } = useServiceState(
    APIService.dashboardService.fetchIndexScopeEmbed,
  );

  React.useEffect(() => {
    invokeDashboardList();
  }, [invokeDashboardList]);

  React.useEffect(() => {
    const dataList = dashboardListData.embeds || [];
    if (dataList.length) {
      invokeDashboard(dataList[0].id);
      setActivePanel(dataList[0].id.toString());
    }
  }, [invokeDashboard, dashboardListData]);

  React.useEffect(() => {
    console.log({ dashboardData });
  }, [dashboardData]);

  // useEffect(() => {
  //   // @ts-ignore
  //   window.tableau._createNewVizesAndStartLoading();
  // });

  const handleDashboardChange = (activeKey: string) => {
    invokeDashboard(+activeKey);
    setActivePanel(activeKey);
  };

  const token = TokenUtil.getToken();
  const dataList = dashboardListData.embeds ? dashboardListData.embeds : [];
  return (
    <MainLayout>
      <iframe
        title="login"
        src={`https://idp.hfrdatabase.com/silent-login/?email=${user.email}&token=${token}`}
        height="0"
        width="0"
      />
      <StyledTabs hideAdd activeKey={activePanel} onChange={handleDashboardChange} type="card">
        {dataList.length &&
          dataList.map(dashboard => (
            <TabPane tab={dashboard.name} key={dashboard.id.toString()} closable={false}>
              <PerfectScrollbar>
                {dashboardData && (
                  <div
                    style={{ display: 'flex', justifyContent: 'center' }}
                    dangerouslySetInnerHTML={{
                      __html: dashboardData.content ? dashboardData.content.replace(/\\/g, '') : '',
                    }}
                  />
                )}
              </PerfectScrollbar>
            </TabPane>
          ))}
      </StyledTabs>
    </MainLayout>
  );
};

const mapStateToProps: MapStateToProps<StateProps, {}, InitialRootReducer> = (state: any) => {
  return {
    user: state.rootReducer.userProfile,
  };
};

export default connect(mapStateToProps)(Dashboard);
