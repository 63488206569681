import { Button, Icon, Table, Tooltip, Typography } from 'antd';
import React, { useRef } from 'react';
import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { SorterResult, TableRowSelection } from 'antd/lib/table';
import styled from 'styled-components';
import RootActionEnums from '../../../redux/actions/root.ActionEnums';
import { FundDetails } from '../../../shared/api/models/FundDetails';
import { FundFirmPane, InitialRootReducer } from '../../../redux/reducers/root.reducer';
import APIService from '../../../shared/api';
import { Colors } from '../../../shared/colors';
import ColumnBuilder from './columnbuilder/ColumnBuilder';
import { allColumns, ColumnPropsExtended, defaultColumns } from './columnbuilder/columnSource';

const TABLE_BODY_MAX_HEIGHT = 'calc(100vh - 375px)';

const Wrapper = styled.div`
  position: relative;
  width: 100%;

  .ant-table-fixed-left,
  .ant-table-body-inner {
    overflow-x: hidden;
  }

  .ant-table-body {
    ::-webkit-scrollbar {
      height: 8px;
    }
    scrollbar-width: thin;
    div::-webkit-scrollbar-track {
      box-shadow: inset 0 0 8px rgba(0, 0, 0, 0.3);
    }

    div::-webkit-scrollbar-thumb {
      background-color: darkgrey;
      outline: 1px solid slategrey;
    }
  }
  .ant-table-fixed-left .ant-table-body-outer .ant-table-body-inner .ant-table-fixed {
    padding-bottom: 11px !important;
  }
`;

const UpdateColumnButton = styled(Button)`
  position: absolute;
  right: 10px;
  top: -30px;
  z-index: 99;
  vertical-align: center;
`;

const FundFirmName = styled(Typography.Paragraph)`
  color: ${Colors.primary};
  cursor: pointer;
  height: 42px;
`;

interface DispatchProps {
  addPane: (data: FundFirmPane) => void;
}

interface Props {
  items: any[]; // TODO: Define Model for result
  selectedItems: string[] | undefined;
  setSelectedItems: (selectedItems: string[]) => void;
  handleSortChange: (sorter: SorterResult<any>) => void;
  searchPanelWidth: number;
  isShowSearch: boolean;
  loading: boolean;
}

interface StateProps {
  fundColumns: ColumnPropsExtended[];
}

const FundsTable: React.FC<DispatchProps & StateProps & Props> = ({
  fundColumns,
  items,
  addPane,
  selectedItems,
  setSelectedItems,
  handleSortChange,
  searchPanelWidth,
  isShowSearch,
  loading,
}) => {
  const tableRef = useRef<Table<FundDetails>>(null);
  const [showColumnBuilder, setShowColumnBuilder] = React.useState(false);

  const rowSelection: TableRowSelection<FundDetails> = {
    selectedRowKeys:
      selectedItems &&
      selectedItems.map(selected => items.findIndex(item => item.fund_id === selected)),
    onChange: (_, selectedRows) => {
      setSelectedItems(selectedRows.map((row: FundDetails) => row.fund_id));
    },
  };

  const data = React.useMemo(() => {
    return items.map((item, i) => {
      return { ...item, key: i };
    });
  }, [items]);

  const columns = React.useMemo(() => {
    if (!fundColumns) {
      return defaultColumns;
    }

    return fundColumns.filter(col => !!col);
  }, [fundColumns]);

  const tableColumns = React.useMemo(() => {
    const dynamicColWidth =
      (window.innerWidth - (isShowSearch ? searchPanelWidth : 0)) / columns.length;
    return columns.map((item, index) => {
      if (item.key === 'fund_name') {
        return {
          ...item,
          fixed: index === 0,
          sorter: true,
          width: dynamicColWidth < 200 ? 200 : dynamicColWidth,
          render: (text: string) => (
            <FundFirmName ellipsis={{ rows: 2 }} style={{ marginBottom: 0 }}>
              {text}
            </FundFirmName>
          ),
          onCell: (record: FundDetails) => ({
            onClick: () => {
              APIService.eventService.sendViewFundProfileEvent(record.fund_id);
              addPane({
                title: record.fund_name,
                key: `${record.fund_id}-fund`,
                type: 'fund',
                fundFirmId: record.fund_id,
              });
            },
          }),
        };
      } else if (item.key === 'firm_name') {
        return {
          ...item,
          sorter: true,
          width: dynamicColWidth < 200 ? 200 : dynamicColWidth,
          render: (text: string) => (
            <FundFirmName ellipsis={{ rows: 2 }} style={{ marginBottom: 0 }}>
              {text}
            </FundFirmName>
          ),
          onCell: (record: FundDetails) => ({
            onClick: () => {
              addPane({
                title: record.firm.firm_name,
                key: `${record.firm.firm_id}-firm`,
                type: 'firm',
                fundFirmId: record.firm.firm_id,
              });
            },
          }),
        };
      }
      return {
        ...item,
        sorter: true,
        width: dynamicColWidth < 200 ? 200 : dynamicColWidth,
        ellipsis: true,
      };
    });
  }, [addPane, columns, isShowSearch, searchPanelWidth]);

  return (
    <Wrapper>
      <Tooltip title="Update Columns" placement="topLeft">
        <UpdateColumnButton type="ghost" size="small" onClick={() => setShowColumnBuilder(true)}>
          <Icon type="more" />
        </UpdateColumnButton>
      </Tooltip>
      <Table
        ref={tableRef}
        loading={loading}
        dataSource={data}
        size="small"
        scroll={{ y: TABLE_BODY_MAX_HEIGHT, x: 'min-content' }}
        columns={tableColumns}
        rowSelection={rowSelection}
        pagination={false}
        onChange={(_pagination, _filter, sorter) => {
          handleSortChange(sorter);
        }}
      />
      {showColumnBuilder && (
        <ColumnBuilder isOpen={showColumnBuilder} setOpen={setShowColumnBuilder} />
      )}
    </Wrapper>
  );
};

const mapStateToProps: MapStateToProps<StateProps, Props, InitialRootReducer> = (state: any) => {
  const flattenAllColumns = allColumns.flatMap(item => item.columns);
  const fundColumnsAsKey = state.rootReducer.fundColumns;

  return {
    fundColumns: fundColumnsAsKey
      ? fundColumnsAsKey.map((key: any) => flattenAllColumns.find(item => item.key === key))
      : defaultColumns,
  };
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, Props> = dispatch => {
  return {
    addPane: (data: FundFirmPane) => {
      dispatch({ type: RootActionEnums.ADD_PANE, payload: data });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FundsTable);
