import React, { useEffect, useState } from 'react';
import { Button, Icon, Input, Modal, notification, Pagination, Row, Table, Tooltip } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { AxiosResponse } from 'axios';
import { connect, MapStateToProps } from 'react-redux';
import {
  ExtendFundListRecord,
  FundListRecord,
  UserProfileDetails,
} from '../../../shared/api/models/ResponsesTypes';
import GenUtil from '../../../shared/utils/gen-util';
import { UniverseSelectTypeEnum } from '../../../shared/components/UniverseSelect';
import ShareModal from '../../../shared/components/ShareModal';
import { UniverseFundListResponse } from '../../../shared/api/services/universe-list.service';
import APIService from '../../../shared/api';
import { CombinedReducers } from '../../../index';
import { Colors } from '../../../shared/colors';
import UniverseDetailModal from './UniverseDetailModal';

const Wrapper = styled('div')`
  display: flex;
  width: 100%;
  padding: 20px;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  & .ant-row {
    margin-bottom: 10px;
    display: flex;
    justify-content: flex-end;
  }
`;

const ActionGroup = styled('div')`
  button {
    margin-right: 2px;
    &:last-child {
      margin-right: 0;
    }
  }
`;

const Meta = styled('div')`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  span {
    margin-right: 16px;
    font-weight: 500;
  }
`;
interface StateProps {
  user: UserProfileDetails;
}

export interface Props {
  listType: string;
  listData: ExtendFundListRecord[];
  refetchTable: () => void;
}

/**
 * Rendering Universe and User-Created-List(My List) Tab for the My-List link
 * @param listType - Type of List
 * @param listData ExtendFundListRecord[] - Complete Data for the given list type
 * @param refetchTable - Function to fetch the updated fund and universe list
 * @constructor
 */
const UniverseFundListTab: React.FC<StateProps & Props> = ({
  user,
  listType,
  listData,
  refetchTable,
}) => {
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [newListName, setNewListName] = useState();
  const [selectedList, setSelectedList] = useState<FundListRecord>();
  const [selectedFunds, setSelectedFunds] = useState<string[]>();
  const [showShareModal, setShowShareModal] = useState(false);
  const [showDetailModal, setShowDetailModal] = useState(false);
  const [dataSource, setDataSource] = useState<ExtendFundListRecord[]>([]);
  const [page, setPage] = useState<number>(1);

  const COLUMNS: ColumnProps<ExtendFundListRecord>[] =
    listType === UniverseSelectTypeEnum.HFR
      ? [
          {
            title: 'List Name',
            dataIndex: 'name',
            key: 'name',
          },
          {
            title: 'Fund count',
            dataIndex: 'fund_count',
            key: 'fund_count',
          },
          {
            title: 'Action',
            key: 'action',
            width: 320,
            render: (
              _: any,
              record: { id: string; name: string; type: string; fund_count: number },
            ) => {
              const isMoreThanLimit = record.fund_count > 50;
              return (
                <ActionGroup>
                  <Tooltip title="Duplicate">
                    <Button
                      type="default"
                      onClick={() => handleUniverseDuplicate(record.id.toString())}
                    >
                      <Icon type="copy" />
                    </Button>
                  </Tooltip>
                  <Tooltip
                    title={
                      isMoreThanLimit
                        ? 'Group PDF is limited to 50 funds'
                        : user.hfrdb_trial
                        ? 'You account do not have permission to Generate Group PDF Report'
                        : 'Generate Group PDF Report'
                    }
                  >
                    <Button
                      type="default"
                      disabled={isMoreThanLimit || user.hfrdb_trial}
                      onClick={() => generateGroupReport(record.type, record.id.toString())}
                    >
                      <Icon style={{ color: Colors.primary }} type="download" />
                    </Button>
                  </Tooltip>
                </ActionGroup>
              );
            },
          },
        ]
      : [
          {
            title: 'List Name',
            dataIndex: 'name',
            key: 'name',
          },
          {
            title: 'Fund count',
            dataIndex: 'fund_count',
            key: 'fund_count',
          },
          {
            title: 'Created At',
            dataIndex: 'created_date',
            key: 'created_date',
          },
          {
            title: 'Updated At',
            dataIndex: 'updated_date',
            key: 'updated_date',
          },
          {
            title: 'Action',
            key: 'action',
            width: 320,
            render: (
              _: any,
              record: { id: string; name: string; type: string; fund_count: number },
            ) => {
              const isMoreThanLimit = record.fund_count > 50;
              return (
                <ActionGroup>
                  <Tooltip title="View Data">
                    <Button type="default" onClick={() => handleViewData(record)}>
                      <Icon type="eye" />
                    </Button>
                  </Tooltip>
                  <Tooltip title="Duplicate">
                    <Button
                      type="default"
                      onClick={() => handleFundDuplicate(record.id.toString())}
                    >
                      <Icon type="copy" />
                    </Button>
                  </Tooltip>
                  <Tooltip title="Update">
                    <Button type="default" onClick={() => handleShowUpdate(record)}>
                      <Icon type="edit" />
                    </Button>
                  </Tooltip>
                  <Tooltip title="Delete">
                    <Button
                      type="danger"
                      onClick={() => handleDelete(record.id.toString(), record.name)}
                    >
                      <Icon type="delete" />
                    </Button>
                  </Tooltip>
                  <Tooltip title="Share">
                    <Button
                      type="default"
                      disabled={user.hfrdb_trial}
                      onClick={() => handleShowShare(record)}
                    >
                      <Icon type="share-alt" />
                    </Button>
                  </Tooltip>

                  <Tooltip
                    title={
                      isMoreThanLimit
                        ? 'Group PDF is limited to 50 funds'
                        : user.hfrdb_trial
                        ? 'Your account do not have permission to Generate Group PDF Report'
                        : 'Generate Group PDF Report'
                    }
                  >
                    <Button
                      type="default"
                      disabled={isMoreThanLimit || user.hfrdb_trial}
                      onClick={() => generateGroupReport(record.type, record.id.toString())}
                    >
                      <Icon style={{ color: Colors.primary }} type="download" />
                    </Button>
                  </Tooltip>
                </ActionGroup>
              );
            },
          },
        ];

  const handleShowAddNew = () => {
    setShowUpdateModal(true);
    setNewListName(undefined);
    setSelectedList(undefined);
  };

  const handleShowUpdate = (record: FundListRecord) => {
    setShowUpdateModal(true);
    setSelectedList(record);
    setNewListName(record.name);
  };

  const handleViewData = (record: FundListRecord) => {
    APIService.eventService.sendMyListAccessEvent();
    setShowDetailModal(true);
    setSelectedList(record);
  };

  const handleShowShare = (record: FundListRecord) => {
    setShowShareModal(true);
    setSelectedList(record);
  };

  const handleDelete = (id: string, name: string) => {
    Modal.confirm({
      title: `Do you want to delete ${name} list?`,
      okText: 'Confirm',
      cancelText: 'Cancel',
      onOk: async () => {
        try {
          await APIService.universeListService.deleteFundList(id);
          toast.success(`${name} was deleted!`);
          refetchTable();
        } catch (error) {
          console.error(error);
          toast.error('Something went wrong. Please try again later!');
        }
      },
    });
  };

  const handleFundDuplicate = async (listId: string) => {
    try {
      // fetch the fund-ids belong to the list id
      const {
        data,
      }: AxiosResponse<UniverseFundListResponse> = await APIService.universeListService.fetchFundsByListId(
        {
          listId,
        },
      );
      setSelectedFunds(data.funds.map(item => item.fund_id));
      setShowUpdateModal(true);
      refetchTable();
    } catch (error) {
      console.error(error);
      toast.error({ message: 'Cannot fetch the funds of the selected list!' });
    }
  };

  const handleUniverseDuplicate = async (listId: string) => {
    try {
      // fetch the fund-ids belong to the list id
      const { data } = await APIService.universeListService.fetchFundByUniverseListId(listId);
      setSelectedFunds(data);
      setShowUpdateModal(true);
      refetchTable();
    } catch (error) {
      console.error(error);
      toast.error({ message: 'Cannot fetch funds of the selected list!' });
    }
  };

  const generateGroupReport = (listType: string, listId: string) => {
    listType === UniverseSelectTypeEnum.HFR
      ? APIService.universeListService.fetchGroupReportByUniverseListId(listId)
      : APIService.universeListService.fetchGroupReportByListId(listId);
    notification.success({ message: 'Please Check Your Inbox For Group PDF Report.' });
  };

  const handleShareSubmit = async (isShareAll: boolean, userIds?: number[]) => {
    if (!selectedList) {
      return;
    }
    try {
      await APIService.universeListService.shareFundList(
        selectedList.id,
        userIds || [],
        isShareAll,
      );
      toast.success(`Fund list ${selectedList.name} shared!`);
      setShowShareModal(false);
      setSelectedList(undefined);
      refetchTable();
    } catch (error) {
      console.error(error);
      toast.error('Cannot share the selected fund list!');
    }
  };

  const handleModalSubmit = async () => {
    if (!newListName.trim()) {
      toast.warn('List name is required!');
      return;
    }

    // Duplicate selected list
    if (selectedFunds) {
      await APIService.universeListService.createFundList(newListName.trim(), selectedFunds);
      toast.success('Duplicated!');
      setShowUpdateModal(false);
      setSelectedList(undefined);
      setSelectedFunds(undefined);
      refetchTable();
      try {
      } catch (error) {
        console.error(error);
        toast.error('Cannot update custom list. Please try again later');
      } finally {
        return;
      }
    }

    // Create new list
    if (!selectedList) {
      try {
        await APIService.universeListService.createFundList(newListName.trim(), []);
        toast.success('Created!');
        setShowUpdateModal(false);
        setSelectedList(undefined);
        setSelectedFunds(undefined);
        refetchTable();
      } catch (error) {
        console.error(error);
        const errorMsg = error.response
          ? error.response.data.message
          : 'Cannot create custom list. Please try again later';
        toast.error(errorMsg);
      } finally {
        return;
      }
    }

    // Update selected list
    try {
      await APIService.universeListService.updateFundList(selectedList.id.toString(), {
        name: newListName.trim(),
      });
      toast.success('Updated!');
      setShowUpdateModal(false);
      setSelectedList(undefined);
      refetchTable();
    } catch (error) {
      toast.error({
        message: 'Cannot create custom list. Please try again later',
        placement: 'bottomLeft',
      });
    }
  };

  const renderModalTitle = () => {
    if (selectedFunds) {
      return 'Duplicate custom list';
    }
    if (!selectedList) {
      return 'Create new custom list';
    }
    return 'Rename custom list';
  };

  useEffect(() => {
    const ds: ExtendFundListRecord[] = listData
      ? listData.slice((page - 1) * 15, page * 15).map((item: ExtendFundListRecord) => ({
          ...item,
          created_date: item.created_date
            ? GenUtil.getFormattedYearMonthAndDay(item.created_date)
            : undefined,
          updated_date: item.updated_date
            ? GenUtil.getFormattedYearMonthAndDay(item.updated_date)
            : undefined,
          key: item.type === UniverseSelectTypeEnum.HFR ? `HFR-${item.id}` : item.id,
        }))
      : [];
    setDataSource(ds);
  }, [listData, listType, page]);
  return (
    <Wrapper>
      <Row>
        {listType === UniverseSelectTypeEnum.LIST && (
          <Button type="primary" onClick={handleShowAddNew}>
            <Icon type="plus" />
            Add New
          </Button>
        )}
      </Row>
      <Table
        bordered
        style={{ flexGrow: 1 }}
        scroll={{ y: 'calc(100vh - 280px)' }}
        dataSource={dataSource}
        pagination={false}
        size="small"
        columns={COLUMNS}
      />
      <Meta>
        <Pagination
          onChange={(p: number) => {
            setPage(p);
          }}
          total={listData ? listData.length : 0}
          current={page}
          pageSize={15}
        />
      </Meta>
      {showUpdateModal && (
        <Modal
          visible={showUpdateModal}
          title={renderModalTitle()}
          onCancel={() => {
            setShowUpdateModal(false);
            setSelectedList(undefined);
            setNewListName(undefined);
            setSelectedFunds(undefined);
          }}
          onOk={handleModalSubmit}
        >
          {selectedFunds && (
            <p>
              <b>{selectedFunds.length} funds in selected list</b>
            </p>
          )}
          <span>List Name</span>
          <Input
            placeholder="Enter name"
            value={newListName}
            onChange={event => setNewListName(event.target.value)}
          />
        </Modal>
      )}
      {showShareModal && (
        <ShareModal
          visible={showShareModal}
          onRequestClose={() => setShowShareModal(false)}
          onSubmit={handleShareSubmit}
        />
      )}
      {showDetailModal && (
        <UniverseDetailModal
          showDetailModal={showDetailModal}
          setShowDetailModal={setShowDetailModal}
          selectedList={selectedList}
          onUpdateUniverseFund={() => refetchTable()}
        />
      )}
    </Wrapper>
  );
};

const mapStateToProps: MapStateToProps<StateProps, Props, CombinedReducers> = (
  state: CombinedReducers,
) => ({
  user: state.rootReducer.userProfile,
});

export default connect(mapStateToProps)(UniverseFundListTab);
