import { AxiosPromise, AxiosInstance } from 'axios';

type DashboardRecord = {
  created_date: string;
  id: number;
  name: string;
  content?: string;
};
type IndexScopeEmbedsResponse = {
  embeds: {
    id: number;
    name: string;
    created_date: string;
  }[];
};

export interface DashboardService {
  fetchDashboardList: () => AxiosPromise<DashboardRecord[]>;
  fetchDashboard: (id: number) => AxiosPromise<DashboardRecord>;
  fetchIndexScopeEmbedList: () => AxiosPromise<IndexScopeEmbedsResponse>;
  fetchIndexScopeEmbed: (id: number) => AxiosPromise<DashboardRecord>;
}

export const DashboardService = (request: AxiosInstance): DashboardService => {
  return {
    fetchDashboardList: () => request.get('/dashboard'),
    fetchDashboard: id => request.get(`/dashboard/${id}`),
    fetchIndexScopeEmbedList: () => request.get('/indexscope-embeds'),
    fetchIndexScopeEmbed: id => request.get(`/indexscope-embeds/${id}`),
  };
};
