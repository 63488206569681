import { AxiosInstance, AxiosPromise } from 'axios';
import { LatestRORData } from '../../../modules/search/SearchView';
import { sanatizeToSnakeCase } from '../../strings';
import {
  FundDetailRegionalFocus,
  FundDetailRegionalFocusCountry,
  FundDetailStrategy,
  FundDetailSubStrategy,
} from '../models/FundDetails';
import {
  BenchmarkRecord,
  CodeRecord,
  ListResponse,
  SearchStatistic,
  ValueRecord,
  OptionType,
  EsgOptionType,
  MinorityType,
  FundDropdownsType,
} from '../models/ResponsesTypes';

export interface FetchFundParams {
  fundOrFirmName: string;
  fundListId: number[];
  universeListId: number[];
  portfolioModelId: number[];
  staticIndexIds: number[];
  dynamicIndexIds: number[];
  peerGroupIds: number[];
  strategy: number[];
  subStrategy: number[];
  ucitsCompliant: boolean;
  liquidAltProduct: boolean;
  liquidAltType: string[];
  liquidAltRegion: string[];
  max_12b1Fee: string[];
  maxFrontFee: string[];
  maxDeferredFee: string[];
  mgmtFee: number[];
  incentiveFee: number[];
  regionalFocus: number[];
  regionInvFocusCountry: number[];
  productClass: string[];
  fundName: string;
  fundId: string;
  firmName: string;
  isEmrgngmrktsFund: boolean;
  isIndexFund: boolean;
  isHfrxFund: boolean;
  isOffshore: boolean;
  isLockup: boolean;
  addedToDbFrom: string;
  isHfruFund: boolean;
  isHfrRiskParityFund: boolean;
  isHfrBlockchainFund: boolean;
  inceptionDateFrom: string;
  addedToDbTo: string;
  inceptionDateTo: string;
  strategyDescription: string;
  firmId: string;
  iardCrdNumber: string;
  secNumber: string;
  secRegistered: boolean;
  isDiversityFirm: boolean;
  nonSecRegCountry: string;
  nonSecRegAuth: string;
  nonSecRegAuthType: string;
  contactWeb: string;
  email: string;
  administrator: string;
  annualPerformAudit: boolean;
  auditDateFrom: string;
  auditDateTo: string;
  auditor: string;
  bankingAgentOnshore: string;
  custodian: string;
  legalAdviserOnshore: string;
  primeBroker: string;
  advanceNotice: number[];
  domicile: string;
  structure: string;
  highWatermark: boolean;
  hurdleRate: string;
  individualAccountMinGte: number;
  individualAccountMinLte: number;
  leverage: string;
  lockup: string;
  minInvestmentCurr: string[];
  minimumInvestmentLte: number;
  minimumInvestmentGte: number;
  additionalInvestmentLte: number;
  additionalInvestmentGte: number;
  openForInvestment: boolean;
  openToInvestorType: string;
  reporting: string[];
  reportingStyle: string[];
  returnsCurrency: string[];
  fundRegistrationCode: string;
  redemptions: string[];
  subscriptions: string[];
  firmAssetsCurrency: string[];
  firmAssetsRepDateFrom: string;
  firmAssetsRepDateTo: string;
  fundAssetsCurrency: string[];
  fundAssetsRepDateFrom: string;
  fundAssetsRepDateTo: string;
  firmUsdValueLte: number;
  firmUsdValueGte: number;
  fundUsdValueLte: number;
  fundUsdValueGte: number;
  sortBy: string;
  orderBy: string;
  //P2P Stats
  latestRORDateFrom: string;
  latestRORDateTo: string;
  latestRORValueGte: number;
  latestRORValueLte: number;
  trackRecordGte: number;
  trackRecordLte: number;
  totalTrackRecordGte: number;
  totalTrackRecordLte: number;
  annRorGte: number;
  annRorLte: number;
  annStdevGte: number;
  annStdevLte: number;
  geoAvgMonthRorGte: number;
  geoAvgMonthRorLte: number;
  highMonthRorGte: number;
  highMonthRorLte: number;
  lowMonthRorGte: number;
  lowMonthRorLte: number;
  stdevGte: number;
  stdevLte: number;
  sharpeRatioGte: number;
  sharpeRatioLte: number;
  oneMonthRorGte: number;
  oneMonthRorLte: number;
  threeMonthRorGte: number;
  threeMonthRorLte: number;
  sixMonthRorGte: number;
  sixMonthRorLte: number;
  oneYearAnnRorGte: number;
  oneYearAnnRorLte: number;
  oneYearAnnStdevGte: number;
  oneYearAnnStdevLte: number;
  oneYearAnnSharpeRatioGte: number;
  oneYearAnnSharpeRatioLte: number;
  twoYearAnnRorGte: number;
  twoYearAnnRorLte: number;
  twoYearAnnStdevGte: number;
  twoYearAnnStdevLte: number;
  twoYearAnnSharpeRatioGte: number;
  twoYearAnnSharpeRatioLte: number;
  threeYearAnnRorGte: number;
  threeYearAnnRorLte: number;
  threeYearAnnStdevGte: number;
  threeYearAnnStdevLte: number;
  threeYearAnnSharpeRatioGte: number;
  threeYearAnnSharpeRatioLte: number;
  fiveYearAnnRorGte: number;
  fiveYearAnnRorLte: number;
  fiveYearAnnStdevGte: number;
  fiveYearAnnStdevLte: number;
  fiveYearAnnSharpeRatioGte: number;
  fiveYearAnnSharpeRatioLte: number;
  sevenYearAnnRorGte: number;
  sevenYearAnnRorLte: number;
  sevenYearAnnStdevGte: number;
  sevenYearAnnStdevLte: number;
  sevenYearAnnSharpeRatioGte: number;
  sevenYearAnnSharpeRatioLte: number;
  tenYearAnnRorGte: number;
  tenYearAnnRorLte: number;
  tenYearAnnStdevGte: number;
  tenYearAnnStdevLte: number;
  tenYearAnnSharpeRatioGte: number;
  tenYearAnnSharpeRatioLte: number;
  max_drawdown_lte: number;
  max_drawdown_gte: number;
  max_drawdown: number;
  ytd_lte: number;
  ytd_gte: number;
  //
  alphaGte: number;
  alphaLte: number;
  betaGte: number;
  betaLte: number;
  rSquareGte: number;
  rSquareLte: number;
  correlationGte: number;
  correlationLte: number;
  upAlphaGte: number;
  upAlphaLte: number;
  upBetaGte: number;
  upBetaLte: number;
  upRSquareGte: number;
  upRSquareLte: number;
  downAlphaGte: number;
  downAlphaLte: number;
  downBetaGte: number;
  downBetaLte: number;
  downRSquareGte: number;
  downRSquareLte: number;
  // contact info
  principals: string;
  firstContact: string;
  secondContact: string;
  address: string;
  city: string;
  state: string;
  country: string;
  postalCode: string;
  phone: string;
  facsimile: string;
  // fund esg
  is_fund_esg: string;
  esg_strategy: string;
  esg_sub_strategy: string;
  //
  app_positivescreen: string;
  app_negativescreen: string;
  app_esgintegration: string;
  app_impactinvest: string;
  app_sustaininvest: string;
  //
  env_greenbuilding: string;
  env_climatechange: string;
  env_cleantech: string;
  env_pollutiontaxes: string;
  env_sustainable: string;
  env_waterconservation: string;
  //
  soc_workplacesafety: string;
  soc_communitydev: string;
  soc_laborrelations: string;
  soc_workplacebenefits: string;
  soc_avoidtobacco: string;
  soc_diversityantibias: string;
  soc_humanrights: string;
  //
  gov_corppolcontrib: string;
  gov_anticorruption: string;
  gov_executivecomp: string;
  gov_boardindependence: string;
  gov_boarddiversity: string;
  //
  team_engagepcm: string;
  //
  data_msci: string;
  data_sustainalytics: string;
  data_bloomberg: string;
  data_factset: string;
  data_internal: string;
  //
  is_minority_or_woman_managed_fund: boolean;
  fund_diversity_and_inclusion_types: string[];
  //
  is_minority_or_woman_owned_firm: boolean;
  firm_diversity_and_inclusion_types: string[];
  // firm esg
  is_firm_esg: boolean;
  applied_firmculture: string;
  applied_investmentprocess: string;
  applied_portfolioconstruction: string;
  applied_esgproducts: string;
  applied_esgintegration: string;
  receive_guidance: string;
  thirdparty_guidance: string;
  future: string;
  applied_esgactivist: string;
  applied_diversityinclusion: string;
  // Firm Co-Investments
  offer_coinvestments: string;
  number_of_deals_per_year: string;
  typical_size: string;
  typical_liquidity: string;
  // Fund Family
  fund_family_id: string;
  fund_family_code: string;
  // other filters
  statsStartDate: string;
  statsEndDate: string;
  stats_benchmark: number;
  // paginations
  limit: number;
  page: number;
}

export interface SearchService {
  fetchLatestRORData: (
    params: Partial<FetchFundParams>,
  ) => AxiosPromise<ListResponse<LatestRORData>>;
  fetchStatistics: (params: Partial<FetchFundParams>) => AxiosPromise<SearchStatistic>;
  fetchFundIds: (params: Partial<FetchFundParams>) => AxiosPromise<ListResponse<string>>;
  fetchStrategies: () => AxiosPromise<FundDetailStrategy[]>;
  fetchSubStrategies: () => AxiosPromise<FundDetailSubStrategy[]>;
  fetchRegionalFocus: () => AxiosPromise<FundDetailRegionalFocusCountry[]>;
  fetchRegionalFocusCountry: () => AxiosPromise<FundDetailRegionalFocus[]>;
  fetchLiquidAltTypes: () => AxiosPromise<CodeRecord[]>;
  fetchLiquidAltRegions: () => AxiosPromise<CodeRecord[]>;
  fetchMax12B1Fees: () => AxiosPromise<ValueRecord[]>;
  fetchMaxFrontFees: () => AxiosPromise<ValueRecord[]>;
  fetchMaxDeferredFees: () => AxiosPromise<ValueRecord[]>;
  fetchProductClassOptions: () => AxiosPromise<CodeRecord[]>;
  fetchBenchmarkOptions: () => AxiosPromise<BenchmarkRecord[]>;
  fetchAdvanceNoticeOptions: () => AxiosPromise<string[]>;
  fetchMinInvestmentCurrOptions: () => AxiosPromise<string[]>;
  fetchReportingOptions: () => AxiosPromise<string[]>;
  fetchReportingStyleOptions: () => AxiosPromise<string[]>;
  fetchReturnsCurrencyOptions: () => AxiosPromise<string[]>;
  fetchRedemptionOptions: () => AxiosPromise<string[]>;
  fetchSubscriptionsOptions: () => AxiosPromise<string[]>;
  fetchFirmAssetCurrencyOptions: () => AxiosPromise<string[]>;
  fetchFundAssetCurrencyOptions: () => AxiosPromise<string[]>;
  fetchNonSecRegAuthorities: () => AxiosPromise<OptionType[]>;
  fetchNonSecCountries: () => AxiosPromise<OptionType[]>;
  fetchCountries: () => AxiosPromise<OptionType[]>;
  fetchEsgStrategies: () => AxiosPromise<EsgOptionType[]>;
  fetchEsgSubStrategies: () => AxiosPromise<EsgOptionType[]>;
  fetchMinorityTypes: () => AxiosPromise<MinorityType[]>;
  fetchFundDropdowns: () => AxiosPromise<FundDropdownsType>;
}

export const SearchService = (request: AxiosInstance): SearchService => {
  return {
    fetchLatestRORData: (params: Partial<FetchFundParams>) =>
      request.post('/funds-api/fund/latest-ror', { ...sanatizeToSnakeCase(params) }),
    fetchStatistics: (params: Partial<FetchFundParams>) =>
      request.post('/funds-api/fund/metadata', { ...sanatizeToSnakeCase(params) }),
    fetchFundIds: (params: Partial<FetchFundParams>) =>
      request.post('/funds-api/fund/ids', { ...sanatizeToSnakeCase(params) }),
    fetchStrategies: () => request.get('/funds-api/data/strategy'),
    fetchSubStrategies: () => request.get('/funds-api/data/sub-strategy'),
    fetchRegionalFocus: () => request.get('/funds-api/data/regional-focus'),
    fetchRegionalFocusCountry: () => request.get('/funds-api/data/regional-focus-country'),
    fetchLiquidAltTypes: () => request.get('/funds-api/data/liquid-alt-types'),
    fetchLiquidAltRegions: () => request.get('/funds-api/data/liquid-alt-regions'),
    fetchMax12B1Fees: () => request.get('/funds-api/data/max-12b1-fees'),
    fetchMaxDeferredFees: () => request.get('/funds-api/data/max-deferred-fees'),
    fetchMaxFrontFees: () => request.get('/funds-api/data/max-front-fees'),
    fetchProductClassOptions: () => request.get('/funds-api/data/product-classes'),
    fetchBenchmarkOptions: () => request.get('/funds-api/data/available-benchmarks'),
    fetchAdvanceNoticeOptions: () => request.get('/funds-api/data/advance-notice-days'),
    fetchMinInvestmentCurrOptions: () => request.get('/funds-api/data/fund-currency-codes'),
    fetchReportingOptions: () => request.get('/funds-api/data/reporting'),
    fetchReportingStyleOptions: () => request.get('/funds-api/data/reporting-style'),
    fetchReturnsCurrencyOptions: () => request.get('/funds-api/data/fund-currency-codes'),
    fetchRedemptionOptions: () => request.get('/funds-api/data/redemptions'),
    fetchSubscriptionsOptions: () => request.get('/funds-api/data/subscriptions'),
    fetchFirmAssetCurrencyOptions: () => request.get('/funds-api/data/firm-currency-codes'),
    fetchFundAssetCurrencyOptions: () => request.get('/funds-api/data/fund-currency-codes'),
    fetchNonSecRegAuthorities: () => request.get('/funds-api/data/firm-non-sec-reg-authorities'),
    fetchNonSecCountries: () => request.get('/funds-api/data/non-sec-countries'),
    fetchCountries: () => request.get('/funds-api/data/countries'),
    fetchEsgStrategies: () => request.get('/funds-api/data/esg-strategies'),
    fetchEsgSubStrategies: () => request.get('/funds-api/data/esg-substrategies'),
    fetchMinorityTypes: () => request.get('/funds-api/data/fund-di-types'),
    fetchFundDropdowns: () => request.get('/funds-api/dropdowns'),
  };
};
